@import url("https://fonts.googleapis.com/css?family=Special+Elite&display=swap");

.App {
    font-family: "Courier", "Special Elite";
    overflow-x: hidden;
}
ul {
    margin: 0;
    padding: 0;
}
ul li {
    display:inline;
  }

.App-header {
    background-color: #e50914;
    color: white;
    font-size: calc(25px);
    height: 57px;
    padding: 10px 10px 10px 20px;
    text-align: left;
    vertical-align: center;
}

.App-link {
    color: #61dafb;
}

.App-content-list {
    padding: 10px 0 0 20px;
}

.grid-container {
    display: grid;
    grid-template-columns: 45px auto;
    padding: 10px;
}
.grid-item {
    padding: 15px 20px;
    font-size: 20px;
    text-align: left;
}

.App-bullets {
    list-style-type: none;
}

.description {
    margin: 15px 0 0 15px;
}

.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 15px;
}

/* Nav Links */
a {
    text-decoration: none;
    padding-right: 15px;
    color: white;
    font-size: calc(18px);
}

/* Loader */
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: 60px auto;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(68, 68, 68, 0.2);
    border-right: 1.1em solid rgba(68, 68, 68, 0.2);
    border-bottom: 1.1em solid rgba(68, 68, 68, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
